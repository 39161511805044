import { useEffect } from 'react';

import { SignUpForm } from 'features/auth';
import { Analytics } from 'features/analytics';

import styles from './index.module.scss';


export const SignUp = () => {
	useEffect(() => {
		Analytics.trackSignupPageView();
	})

	return (
		<section className={styles.container}>
			<main className={styles.login}>
				<SignUpForm className={styles.login} />
			</main>
			<section className={styles.image}></section>
		</section>
	);
};
