import React, { FC, useState } from 'react';
import cn from 'classnames';

import { Icon, Text, TextButton } from 'shared/components/ui';
import { ProgAISoBadge } from 'shared/generated-models';

import styles from './index.module.scss';

interface Props {
	so_badges?: ProgAISoBadge[];
	so_stats?: string[];
}

// const ITEMS_TO_SHOW = 8;

export const CandidateStackoverflow: FC<Props> = ({ so_badges, so_stats }: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(open => !open);
	};

	// const firstProgLanguages = so_badges.slice(0, ITEMS_TO_SHOW);

	return (
		<div>
			<div className={styles.title}>
				<Icon icon="stackoverflow-logo" size={26} />
				<Text component="h3" variant="inter/18/semi">
					Stack Overflow
				</Text>
			</div>
			{so_stats && so_stats.length > 0 && (
				<section className={styles.stats}>
					<Text variant="inter/14/medium" color="grey">
						Stats
					</Text>
					<div className={styles.statsWrapper}>
						<div className={styles.statsRow}>
							<div className={styles.statsColumn}>
								<div className={styles.statsIndividual}>
									<Text variant="inter/16/medium">{so_stats[0].split(' ')[0]}</Text>
									<Text variant="inter/14/regular" color="lighter_grey">
										{so_stats[0].split(' ')[1]}
									</Text>
								</div>
								{so_stats.length > 2 && (
									<div className={styles.statsIndividual}>
										<Text variant="inter/16/medium">{so_stats[2].split(' ')[0]}</Text>
										<Text variant="inter/14/regular" color="lighter_grey">
											{so_stats[2].split(' ')[1]}
										</Text>
									</div>
								)}
							</div>
							<div className={styles.statsColumn}>
								{so_stats.length > 1 && (
									<div className={styles.statsIndividual}>
										<Text variant="inter/16/medium">{so_stats[1].split(' ')[0]}</Text>
										<Text variant="inter/14/regular" color="lighter_grey">
											{so_stats[1].split(' ')[1]}
										</Text>
									</div>
								)}
								{so_stats.length > 3 && (
									<div className={styles.statsIndividual}>
										<Text variant="inter/16/medium">{so_stats[3].split(' ')[0]}</Text>
										<Text variant="inter/14/regular" color="lighter_grey">
											{so_stats[3].split(' ')[1]}
										</Text>
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
			)}
			{so_badges && so_badges.length > 0 && (
				<section className={styles.badges}>
					<Text variant="inter/14/medium" color="grey">
						Badges
					</Text>
					<div className={styles.badgesList}>
						{so_badges.map(badge => (
							<div className={styles.badge}>
								<Text variant="inter/14/medium" color="orange">
									{badge.skill}
								</Text>
								<div className={styles.badgePercent}>{badge.badge}</div>
							</div>
						))}
					</div>
				</section>
			)}
			{/* <section className={cn(styles.section, styles.sectionProgramming)}>
				{firstProgLanguages.map(language => (
					<Text variant="inter/14/medium" key={language}>
						{language}
					</Text>
				))}
				{isOpen &&
					programming_languages.slice(ITEMS_TO_SHOW, programming_languages.length).map(language => (
						<Text variant="inter/14/medium" key={language}>
							{language}
						</Text>
					))}
			</section> */}
			{/* {programming_languages.length > ITEMS_TO_SHOW && (
				<TextButton onClick={handleOpen} className={styles.open}>
					{isOpen ? 'See less' : `+${programming_languages.length - ITEMS_TO_SHOW} more`}
				</TextButton>
			)} */}
		</div>
	);
};
