import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'antd';
import { FormikProvider, useFormik } from 'formik';

import { passwordResetFormValidationSchema } from 'features/registration';
import { PasswordResetForm } from 'shared/models';
import { Button, FormikInputPassword, Icon, Text } from 'shared/components/ui';
import { Empty } from 'shared/components';
import { environment } from 'environments/environment';

import styles from './index.module.scss';

export const ResetPassword = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [error, setError] = useState<string | null>(null);

	const uid = searchParams.get('uid') || '';
	const token = searchParams.get('token') || '';

	const handleSubmit = (values: PasswordResetForm) => {
		const resend = async () => {
			try {
				if (values.password !== values.password1) {
					setError('Password do not match!');
					return;
				}
				const response = await fetch(`${environment.apiUrl}/password-reset-confirm/${uid}/${token}/`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						password: values.password,
						confirm_password: values.password1
					})
				});

				if (!response.ok) {
					throw new Error('Failed to sign in');
				}
				navigate('/password-reset-complete');
			} catch (error) {
				console.error('Sign-in error:', error);
			}
		};
		resend();
	};

	const formik = useFormik<PasswordResetForm>({
		validationSchema: passwordResetFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			password: '',
			password1: ''
		},
		onSubmit: values => handleSubmit(values)
	});

	return (
		<>
			<Icon
				icon="logo-login"
				className={styles.logo}
				size={65}
				onClick={() => {
					navigate('/login');
				}}
			/>
			<Empty
				className={styles.empty}
				title={
					<Text variant="inter/32/semi" component="h1">
						Change Your Password
					</Text>
				}
				icon="">
				<FormikProvider value={formik}>
					<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
						<FormikInputPassword
							className={styles.input}
							name="password"
							label="New password"
							size="large"
							placeholder="Password"
						/>
						<FormikInputPassword
							className={styles.input}
							name="password1"
							label="Confirm new password"
							size="large"
							placeholder="Repeat password"
						/>
						{error && (
							<div style={{ marginBottom: '16px' }}>
								<Text variant="inter/14/regular" color="red">
									{error}
								</Text>
							</div>
						)}
						<Button
							className={styles.signInButton}
							type="primary"
							block
							onClick={formik.handleSubmit}
							disabled={!formik.isValid}>
							Reset password
						</Button>
					</Form>
				</FormikProvider>
			</Empty>
		</>
	);
};
