import React, { FC, useState } from 'react';
import cn from 'classnames';

import { Icon, Text, TextButton } from 'shared/components/ui';
import { ProgAICandidate } from 'shared/generated-models';

import { CandidateProgLanguages } from '../CandidateProgLanguages';
import { CandidateStackoverflow } from '../CandidateStackoverflow';

import styles from './index.module.scss';

interface Props {
	data: ProgAICandidate['profile'];
}

const ITEMS_TO_SHOW = 15;

export const CandidateSkills: FC<Props> = ({ data }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	if (!data) return null;

	const handleOpen = () => {
		setIsOpen(open => !open);
	};

	return (
		<section className={styles.wrapper}>
			{((data.so_badges && data.so_badges.length > 0) || (data.so_stats && data.so_stats.length > 0)) && (
				<CandidateStackoverflow so_badges={data.so_badges} so_stats={data.so_stats} />
			)}
			{data.skills && data.skills.length > 0 && (
				<>
					<div className={cn(styles.title, styles.titleInline)}>
						<Icon icon="github-logo-circle" size={20} />
						<Text component="h3" variant="inter/18/semi">
							Github Skills ({data.skills?.length})
						</Text>
					</div>
					<div className={cn(styles.section, styles.sectionSkills)}>
						{data.skills?.slice(0, ITEMS_TO_SHOW).map(skill => (
							<div key={skill.skill}>
								<div className={styles.skillInfo}>
									<Text variant="inter/14/medium">{skill.skill}</Text>
									<Text color="grey">{skill.weight}</Text>
								</div>
								<div className={styles.progressBar}>
									<div className={styles.progress} style={{ width: `${skill.weight * 10}%` }}></div>
								</div>
							</div>
						))}
						{isOpen &&
							data.skills?.slice(ITEMS_TO_SHOW, data.skills.length).map(skill => (
								<div key={skill.skill}>
									<div className={styles.skillInfo}>
										<Text variant="inter/14/medium">{skill.skill}</Text>
										<Text color="grey">{skill.weight}</Text>
									</div>
									<div className={styles.progressBar}>
										<div
											className={styles.progress}
											style={{ width: `${skill.weight * 10}%` }}></div>
									</div>
								</div>
							))}
					</div>
				</>
			)}

			{data.skills && data.skills.length > ITEMS_TO_SHOW && (
				<div className={styles.more}>
					<TextButton onClick={handleOpen}>
						{isOpen ? 'See less' : `${data.skills.length - ITEMS_TO_SHOW} more skills`}
					</TextButton>
				</div>
			)}
			{data.programming_languages && data.programming_languages.length > 0 && (
				<CandidateProgLanguages programming_languages={data.programming_languages} />
			)}
		</section>
	);
};
