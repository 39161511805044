import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'antd';
import cn from 'classnames';

import { loginFormValidationSchema } from 'features/registration';
import { LoginEditForm } from 'shared/models';
import { Button, FormikInput, Icon, Text } from 'shared/components/ui';
import { useAppDispatch } from 'store';
import { setCredentials } from 'store/credentials';
import { parseJwt } from 'shared/utils';
import { Analytics } from 'features/analytics';
import { environment } from 'environments/environment';
import { Divider, GoogleSocialButton, MicrosoftSocialButton } from 'features/auth/components';

import styles from './index.module.scss';

interface Props {
	className?: string;
}

export const LoginForm: FC<Props> = ({ className }: Props) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const accessToken = searchParams.get('access');
	const refreshToken = searchParams.get('refresh');
	const isNew = searchParams.get('is_new');

	const handleSubmit = async (values: LoginEditForm) => {
		try {
			const response = await fetch(`${environment.apiUrl}/users/exists/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					email: values.email
				})
			});

			const data = await response.json();
			const exists = data.exists;

			if (exists) {
				navigate(`/sign-in?email=${encodeURIComponent(values.email!)}`, {
					replace: true
				});
			} else {
				navigate(`/sign-up?email=${encodeURIComponent(values.email!)}`, {
					replace: true
				});
			}
		} catch (error) {
			console.error('Sign-in error:', error);
		}
	};

	const formik = useFormik<LoginEditForm>({
		validationSchema: loginFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			email: ''
		},
		onSubmit: values => handleSubmit(values)
	});

	useEffect(() => {
		if (accessToken && refreshToken) {
			dispatch(setCredentials({ accessToken, refreshToken }));

			const parsed = parseJwt(accessToken);
			console.log('sending trackLogin');
			Analytics.trackLogin({
				isNew: isNew === 'True',
				id: parsed.user_id.toString(),
				billingPlan: parsed.billing_plan
			});

			navigate(isNew === 'False' ? '/' : '/registration/form', {
				replace: true
			});
		}
	}, [accessToken, dispatch, isNew, navigate, refreshToken]);

	useEffect(() => {
		Analytics.trackLoginPageView();
	}, []);

	const handleClick = () => {
		window.open('https://www.getprog.ai/', '_blank');
	};

	const handleContinueWithEmail = () => {
		Analytics.trackContinueWithEmailButtonClicked(formik.values.email || '');
		formik.handleSubmit();
	};

	return (
		<div className={cn(styles.login, className)}>
			<Icon icon="logo-login" className={styles.logo} size={65} onClick={handleClick} />
			<div className={styles.card}>
				<div className={styles.cardTop}>
					<Text variant="inter/32/semi">Try Prog.AI for free. Upgrade later</Text>
					<Text variant="inter/16/regular" component="p">
						14-day free trial. No card required
					</Text>
				</div>
				<FormikProvider value={formik}>
					<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
						<FormikInput
							className={styles.input}
							name="email"
							label="Email"
							size="large"
							placeholder="Email"
						/>
						<div className={styles.cardBody}>
							<Button
								className={styles.signInButton}
								type="primary"
								block
								onClick={handleContinueWithEmail}
								disabled={!formik.isValid}>
								Continue with email
							</Button>
							<Divider />
							<GoogleSocialButton text="Continue with Google" />
							<MicrosoftSocialButton text="Continue with Microsoft" />
						</div>
					</Form>
				</FormikProvider>
			</div>
			<Text variant="grotesk/12/regular" lineHeight={106} component="p" color="grey" className={styles.loginHint}>
				By&nbsp;proceeding, you agree to&nbsp;our{' '}
				<a href="https://getprog.ai/terms-conditions" target="_blank" rel="noreferrer">
					Terms of&nbsp;Use
				</a>{' '}
				and confirm you have read our{' '}
				<a href="https://getprog.ai/privacy-policy" target="_blank" rel="noreferrer">
					Privacy and Cookies Statement
				</a>
				.
			</Text>
		</div>
	);
};
