import { FC, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import cn from 'classnames';

import { useAppDispatch } from 'store';
import { setCredentials } from 'store/credentials';
import { Button, FormikInput, FormikInputPassword, Icon, Text } from 'shared/components/ui';
import { signInFormValidationSchema } from 'features/registration';
import { SignInEditForm } from 'shared/models';
import { environment } from 'environments/environment';
import { Divider, GoogleSocialButton, MicrosoftSocialButton } from 'features/auth/components';
import { Analytics } from 'features/analytics';

import styles from './index.module.scss';

interface Props {
	className?: string;
}

export const SignInForm: FC<Props> = ({ className }: Props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const [error, setError] = useState<string | null>(null);

	const queryEmail = searchParams.get('email') || '';

	const handleSubmit = async (values: SignInEditForm) => {
		try {
			const response = await fetch(`${environment.apiUrl}/sign-in/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					email: values.email,
					password: values.password
				})
			});

			if (!response.ok) {
				setError('Invalid credentials. Try again');
				throw new Error('Failed to sign in');
			}
			const data = await response.json();

			const accessToken = data.access;
			const refreshToken = data.refresh;

			dispatch(setCredentials({ accessToken, refreshToken }));

			navigate('/', {
				replace: true
			});
		} catch (error) {
			console.error('Sign-in error:', error);
		}
	};

	const formik = useFormik<SignInEditForm>({
		validationSchema: signInFormValidationSchema,
		enableReinitialize: true,
		validateOnMount: false,
		validateOnBlur: false,
		validateOnChange: false,
		initialValues: {
			email: queryEmail,
			password: ''
		},
		onSubmit: values => handleSubmit(values)
	});

	const handleClick = () => {
		navigate('/login');
	};

	const handleForgotPasswordClick = () => {
		Analytics.trackForgotPasswordButtonClick();
		navigate('/forgot-password');
	};

	const handleCreateAnAccountClick = () => {
		navigate('/login');
	};

	const handleSignInClick = () => {
		Analytics.trackSignInButtonClicked(formik.values.email || '');
		formik.handleSubmit();
	};

	return (
		<div className={cn(styles.login, className)}>
			<Icon icon="logo-login" className={styles.logo} size={65} onClick={handleClick} />
			<div className={styles.card}>
				<div className={styles.cardTop}>
					<Text variant="inter/24/semi" className={styles.cardTitle}>
						Welcome to Prog.AI
					</Text>
					<FormikProvider value={formik}>
						<Form className={styles.form} layout="vertical" requiredMark={false} name="registration">
							<FormikInput
								className={styles.input}
								name="email"
								label="Email"
								size="large"
								placeholder="Email"
							/>
							<FormikInputPassword
								className={styles.input}
								name="password"
								label="Password"
								size="large"
								placeholder="Password"
							/>
							<button
								type="button"
								onClick={handleForgotPasswordClick}
								className={cn(styles.link, styles.linkRight)}>
								<span>Forgot password?</span>
							</button>
							{error && (
								<div style={{ marginBottom: '16px' }}>
									<Text variant="inter/14/regular" color="red">
										{error}
									</Text>
								</div>
							)}
							<div className={styles.buttons}>
								<Button
									className={styles.loginButton}
									type="primary"
									block
									onClick={handleSignInClick}
									disabled={!formik.isValid}>
									Sign In
								</Button>
								<Divider />
								<GoogleSocialButton text="Sign in with Google" />
								<MicrosoftSocialButton text="Sign in with Microsoft" />
								<Text variant="inter/14/regular" color="grey">
									New to Prog.AI?{' '}
									<button onClick={handleCreateAnAccountClick} className={styles.link}>
										<span>Create an account</span>
									</button>
								</Text>
							</div>
						</Form>
					</FormikProvider>
				</div>
			</div>
			<span></span>
		</div>
	);
};
